<template>
    <div class="container-xl">
      <div class="card">
        <div class="card-header justify-content-between">
          <div class="card-title">{{ $route.name }} Form</div>
        </div>
        <div class="card-body col-lg-12">
          <div v-if="err_show" class="alert alert-danger w-1/4" role="alert">
            <font-awesome-icon icon="exclamation-triangle" class="text-danger"/>
            <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{ err_msg }}
          </div>
          <b-form @submit.prevent="doSave">
            <b-form-group label="Adjusment Amount">
              <el-input
                v-model="v$.form.nominal_adjustment.$model"
                placeholder="Input adjustment amount"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.nominal_adjustment.$invalid" v-if="v$.form.nominal_adjustment.$errors[0]">
                <span> {{ v$.form.nominal_adjustment.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Vendor">
              <el-select
                class="w-full"
                v-model="v$.form.vendor_id.$model"
                placeholder="Select Vendor">
                <el-option v-for="(item, index) in list" :value="item.vendor_id" :key="index" :label="item.vendor_name">
                  <div class="flex" style="max-height: 70px">
                    <span class="ml-2">{{ item.vendor_name }}</span>
                  </div>
                </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.vendor_id.$invalid" v-if="v$.form.vendor_id.$errors[0]">
                <span> {{ v$.form.vendor_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Adjustment Type">
              <el-select
                class="w-full"
                v-model="v$.form.adjustment_type.$model"
                placeholder="Select Adjustment Type">
                <el-option v-for="(item, index) in list_type" :value="item.name" :key="index" :label="item.label">
                  <div class="flex" style="max-height: 70px">
                    <span class="ml-2">{{ item.label }}</span>
                  </div>
                </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.adjustment_type.$invalid" v-if="v$.form.adjustment_type.$errors[0]">
                <span> {{ v$.form.adjustment_type.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Adjument Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.report_date.$model"
                type="date"
                placeholder="Pick date"/>
              <b-form-invalid-feedback :state="!v$.form.report_date.$invalid" v-if="v$.form.report_date.$errors[0]">
                <span> {{ v$.form.report_date.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Description">
              <el-input
                v-model="v$.form.description.$model"
                placeholder="Input description about this adjustment"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.description.$invalid" v-if="v$.form.description.$errors[0]">
                <span> {{ v$.form.description.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
        <div class="card-footer">
          <div class="d-flex flex-row float-right">
            <el-button @click="doSave" :loading="isLoading" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="$router.back()" size="small" class="mr-2">Cancel</el-button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import {
  POST_ADJUSTMENT,
  GET_VENDORS,
} from '@/store/modules/topups';
import moment from 'moment';

export default {
  name: 'Topup',
  metaInfo: {
    title: 'Adjustment',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      list: [],
      list_type: [
        {
          name: 'debit',
          label: 'Debit',
        },
        {
          name: 'credit',
          label: 'Credit',
        },
      ],
      moment,
      form: {
        nominal_adjustment: '',
        vendor_id: '',
        adjustment_type: '',
        description: '',
        report_date: '',
      },
      err_show: false,
      isLoading: false,
      err_msg: this.$t('response_error.general_error'),
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  validations() {
    return {
      form: {
        nominal_adjustment: {
          required,
          numeric,
        },
        vendor_id: {
          required,
        },
        adjustment_type: {
          required,
        },
        description: {
          required,
        },
        report_date: {
          required,
        },
      },
    };
  },
  computed: {
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getVendorList();
    this.v$.form.$touch();
    loader.hide();
  },
  methods: {
    async getVendorList() {
      this.loading = true;
      await this.$store.dispatch(GET_VENDORS, {
      }).catch(() => {});
      const { rows, count } = this.$store.getters.vendors_topup;
      this.total_rows = count || 0;
      this.list = rows;
      this.loading = false;
    },
    async doSave() {
      console.log('dosave');
      this.v$.form.$touch();
      if (this.v$.form.$error) {
        for (const d in this.statusForm) {
          if (d) this.statusForm[d] = true;
        }
        return;
      }
      this.$parent.showLoader();
      this.isLoading = true;
      this.err_show = false;
      console.log('before post');
      // this.form.report_date = moment().format('YYYY-MM-DD');
      await this.$store.dispatch(POST_ADJUSTMENT, {
        vendor_id: this.form.vendor_id,
        nominal: this.form.nominal_adjustment,
        nominal_type: this.form.adjustment_type,
        description: this.form.description,
        report_date: this.form.report_date,
      }).then(() => {
        this.form = {
          nominal_adjustment: '',
          vendor_id: '',
          adjustment_type: '',
          description: '',
          report_date: '',
        };
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Adjustment Success',
        });
      }).catch((err) => {
        console.log('catch');
        console.log(err);
        if (err.response?.data?.error) {
          const k = `response_error.${err.response?.data?.error.title}`;
          this.err_msg = this.$t(k);
          if (this.err_msg === k) {
            this.err_msg = err.response?.data?.error.message;
          }
        }
        this.err_show = true;
      });
      this.$parent.hideLoader();
      this.isLoading = false;
    },
  },
};
</script>
